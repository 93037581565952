import Link from "../link/Link";
import Image from "next/image";
import {BxRichText} from "./RichText";
import React, {useEffect, useRef} from "react";
import {convertBxAttributes} from "../../utils/bxAttributes";
import {imageSizes} from "../../utils/imageSizes";
import single from "../../utils/single";
import {Swiper, SwiperSlide, useSwiper, useSwiperSlide} from "swiper/react";
import {FreeMode, Mousewheel, Navigation, Pagination} from "swiper/modules";

interface Props {
    narrative: any;
}

interface TileProps {
    block: any;
    mediaClassName?: string;
    autoplayDelay?: number;
}
function Tile({block, mediaClassName = '', autoplayDelay}: TileProps) {
    const swiperSlide = useSwiperSlide();
    const swiper = useSwiper();
    const videoRef = useRef<HTMLVideoElement>(null);

    const url = single(block.image);
    const isVideo = url.endsWith('.webm') || url.endsWith('.mp4');

    async function startVideo() {
        if (!videoRef.current) {
            return;
        }
        videoRef.current.currentTime = 0;
        await videoRef.current.play();
    }

    function stopVideo() {
        videoRef.current?.pause();
    }

    function isVideoPaused() {
        return videoRef.current?.paused ?? false;
    }

    useEffect(() => {
        const listener = async () => {
            if (!document.hidden && isVideoPaused()) {
                await startVideo();
            }
        }

        if (!swiperSlide || swiperSlide.isActive) {
            document.addEventListener('visibilitychange', listener);
        }

        return () => {
            document.removeEventListener('visibilitychange', listener);
        };
    }, [swiperSlide?.isActive]);

    useEffect(() => {
        if (!swiperSlide) {
            return;
        }

        if (swiperSlide.isActive) {
            void startVideo();
        } else {
            stopVideo();
        }
    }, [swiperSlide?.isActive]);

    useEffect(() => {
        if (isVideo || !autoplayDelay || !swiperSlide?.isActive) {
            return;
        }
        const timeout = setTimeout(
            () => {
                swiper?.slideNext()
            },
            autoplayDelay
        );
        return () => {
            clearTimeout(timeout);
        };
    }, [swiperSlide?.isActive, isVideo, autoplayDelay]);

    return <Link {...convertBxAttributes(block, 'd-block position-relative h-100')} href={single(block.link)}>
        {isVideo
            ? <video
                className={`d-block w-100 h-auto rounded-4 ${mediaClassName}`}
                src={url}
                autoPlay={!swiperSlide}
                loop={!swiperSlide}
                muted
                disablePictureInPicture
                width={1156}
                height={396}
                playsInline
                onEnded={() => {
                    swiper?.slideNext();
                }}
                ref={videoRef}
            />
            : <Image src={url}
                     width={1156}
                     height={396}
                     alt={block.name}
                     priority
                     sizes={imageSizes({
                         xs: 'calc(100vw - 24px)',
                         sm: '516px',
                         md: '696px',
                         lg: '936px',
                         xl: '1089px',
                         xxl: '1156px'
                     })}
                     className={`object-fit-contain h-auto w-100 rounded-4 ${mediaClassName}`}
            />}
        <div className="mt-1 fs-7"><BxRichText content={single(block.title)}/></div>
    </Link>;
}

export default function BxHeroTileWrapper({narrative}: Props) {
    const tileBlocks: any[] = narrative.blocks;
    if (tileBlocks.length === 0) return <></>;

    const autoplayDelay = Number(single(narrative['autoplay-delay']));

    return <div {...convertBxAttributes(narrative, 'container mt-4')}>
        <div className="row g-2">
            <div className="col">
                {tileBlocks.length === 1
                    ? <Tile block={tileBlocks[0]} />
                    : <Swiper
                        className="rounded-4 hero-tile-slider"
                        slidesPerView={1}
                        mousewheel={{forceToAxis: true}}
                        freeMode={{sticky: true}}
                        pagination={{clickable: true}}
                        modules={[Mousewheel, FreeMode, Navigation, Pagination]}
                        navigation={true}
                        loop={true}
                    >
                        {tileBlocks.map((block, index) => <SwiperSlide key={index}>
                            <Tile block={block} mediaClassName="mb-4" autoplayDelay={autoplayDelay} />
                        </SwiperSlide>)}
                    </Swiper>
                }
            </div>
        </div>
    </div>;
}
